<template>
  <div class="footer-container">
    <div class="footer-wrap">
      <div class="left">
        <a href=""><img src="../assets/picture/logo01.png" alt="" /></a>
      </div>
      <div class="center">
        <div>
          <h3><span>在家双语早教</span><span>就选启智茗星</span></h3>
          <ul>
            <li>
              <a @click="goPage(0)">首页</a>
            </li>

            <li>
              <a @click="goPage(1)">宝妈案例</a>
            </li>
            <li>
              <a @click="goPage(2)">课程理念</a>
            </li>
            <li>
              <a @click="goPage(3)">课程体系</a>
            </li>
            <li>
              <a @click="goPage(4)">关于我们</a>
            </li>
          </ul>
          <h6>
            <p>
              西安启智茗星信息科技有限公司版权所有&nbsp;<a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                style="color: rgb(255, 255, 255); text-decoration: underline"
                ><span style="color: rgb(255, 255, 255)"
                  >陕ICP备2022012060号-1</span
                ></a
              >
            </p>
          </h6>
        </div>
        <dl class="fl">
          <h3>
            <img
              src="../assets/picture/22d5e86cb44d298.jpg"
              alt=""
              width="120"
              height="120"
            />
          </h3>
          <h5>启智茗星官方公众号</h5>
        </dl>
      </div>

      <div class="right">
        <img src="../assets/picture/footer_top.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup name="footer">
import { useRouter } from "vue-router";
const router = useRouter();
let nameList = ["Home", "Mother", "Course", "System", "About"];
const goPage = (index) => {
  router.push({
    name: nameList[index],
  });
};
</script>
<style lang="scss" scoped>
.footer-container {
  height: 246px;
  width: 100%;
  background: url("../assets/images/footer_bg.jpg") center no-repeat;
  overflow: hidden;

  .footer-wrap {
    width: 1200px;
    height: 256px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      padding: 60px 48px 0 0;
    }

    .center {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      h3 {
        font-size: 22px;
        color: #5fc9b9;

        span {
          margin: 0 11px;
          letter-spacing: 3px;
        }
      }

      ul {
        li {
          padding: 15px 12px;
          font-size: 16px;
          color: #fff;
          display: inline-block;
          position: relative;

          a {
            font-size: 14px;
            color: #fff;
            position: relative;
            transition: 0.36s;
            cursor: pointer;
          }
        }

        li:before {
          content: "";
          position: absolute;
          right: 0;
          width: 1px;
          height: 16px;
          background: #fff;
          top: 50%;
          margin-top: -7px;
        }

        li:last-child:before {
          display: none;
        }

        li:hover a {
          color: #f8b62e;
        }
      }

      h6 {
        font-size: 12px;
        color: #fff;
        position: relative;
      }

      dl {
        text-align: center;
        margin: -25px 0 0 350px;

        img {
          border-radius: 8px;
          margin-bottom: 15px;
        }

        h5 {
          font-size: 13px;
          color: #fff;
        }
      }
      .fl {
        margin-top: -25px;
      }
    }

    .right {
      padding: 40px 0 0;
    }
  }
}
</style>