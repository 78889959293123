<template>
  <div class="container" ref="content" @scroll="scrolling">
    <Header @showfree="changeGive(true)" />

    <RouterView />
    <Suspension v-if="showSuspension" />

    <Give :show="showGive" @notshow="changeGive(false)" />
    <Footer />
  </div>
</template>

<script setup >
import { ref, onMounted, onUnmounted, watch } from "vue";
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import Suspension from "./components/suspension.vue";
import Give from "./components/give.vue";
import {
  RouterView,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from "vue-router";

const router = useRouter();

const route = useRoute();
const content = ref();
const showSuspension = ref(false);
const showGive = ref(false);
const changeGive = (val) => {
  showGive.value = val;
};

watch(
  () => route.name,
  (newPath, oldPath) => {
    document.documentElement.scrollTop = 0;
    showSuspension.value = newPath == "Home" ? false : true;
  },
  { immediate: true }
);

onMounted(() => {
  document.addEventListener("scroll", (e) => {
    const scrollTop = document.documentElement.scrollTop;

    if (route.name == "Home") {
      if (scrollTop > 576 && scrollTop < 5275) {
        showSuspension.value = true;
      } else {
        showSuspension.value = false;
      }
    } else {
      showSuspension.value = true;
    }
  });
});
</script>


<style scoped lang="scss">
@import "./assets/a_reset.css";

.container {
  height: 100%;

  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
