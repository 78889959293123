<template>
    <div class="suspension  on " data-aos="fade-left" data-aos-duration="1500">
        <div class="box">
            <h4></h4>
            <h3><img src="../assets/picture/3df1f29ca0de0c7.jpg" alt="" width="110" height="110"></h3>
        </div>
    </div>
</template>
<script></script>
<style lang="scss" scoped>
.suspension {
    position: fixed;
    background: url(../assets/images/piaofu_bg.png) center no-repeat;
    width: 147px;
    height: 428px;
    right: 15px;
    bottom: 50%;
    z-index: 1000000;
    margin-bottom: -200px;
    opacity: 0 !important;
    transition: 0.6s;

    .box {
        width: 136px;
        height: 170px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -65px;
        text-align: center;
        padding: 0 8px;

        h4 {
            font-size: 16px;
            color: #fff;
        }

        h3 {
            margin: 5px 0 4px;

            img {
                border-radius: 8px;
            }
        }

        h2 {
            font-size: 20px;
            color: #fff;
            margin-bottom: 4px;

            font {
                font-family: BarlowCondensed-Regular;
                font-weight: bold;
            }
        }

        h5 {
            font-size: 14px;
            color: #fff;
            line-height: 14px;
        }

    }





}

.on {
    opacity: 1 !important
}</style>