import { createApp } from "vue";
import App from "./App.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "./router";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
const appAOS = new AOS.init({ disable: "phone" });

createApp(App).use(router).use(appAOS).mount("#app");


