<template>
  <div class="header-container">
    <div class="top-h">
      <div class="top-content">
        <div class="logo-left">
          <a @click="goPage(0)"
            ><img src="../assets/picture/logo.png" alt=""
          /></a>
        </div>
        <div class="nav-rigth">
          <div class="nav-list">
            <nav>
              <ul>
                <li
                  @mouseenter="navMark = 0"
                  @mouseout="navMark = navActive"
                  :class="{ on: navMark == 0 }"
                >
                  <a @click="goPage(0)">首页</a>
                </li>
                <li
                  @mouseenter="navMark = 1"
                  @mouseout="navMark = navActive"
                  :class="{ on: navMark == 1 }"
                >
                  <a @click="goPage(1)">宝妈案例</a>
                  <div class="hot">
                    <svg
                      t="1596004056968"
                      class="icon"
                      viewBox="0 0 1889 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="4115"
                    >
                      <path
                        d="M1476.608 54.784h-1034.24c-175.616 0-327.168 123.392-361.984 295.936-40.448 199.68 88.576 394.752 288.256 435.2V962.56c0 9.728 4.096 18.944 10.752 26.112 14.336 14.336 37.888 14.336 52.224 0l195.072-195.072h849.408c203.776 0 369.152-165.376 369.152-369.152 0.512-204.8-164.864-369.664-368.64-369.664z m-866.304 511.488c-75.264 70.656-193.536 67.584-264.704-7.68-32.256-34.304-50.688-79.36-50.688-126.976v-5.12c0-12.288 1.024-24.576 3.584-36.864 17.92 20.48 43.008 33.28 70.144 36.352v-2.048c0-91.136 66.048-169.472 155.648-184.832-17.92 66.56 2.048 137.216 52.224 184.832 27.136-33.28 41.984-74.752 41.472-117.76v-4.608c71.168 75.264 67.584 193.536-7.68 264.704z m435.2 5.12h-54.784V462.848h-117.248v108.544H819.2V312.832h54.272v103.424h117.248V312.832h54.272v258.56z m175.616 7.168c-76.8 0-124.928-52.224-124.928-137.216s48.128-137.216 126.464-137.216l-1.536 0.512c76.8 0 124.928 52.224 124.928 136.704-0.512 84.48-48.128 137.216-124.928 137.216z m373.248-220.672h-78.336v214.016h-54.272V357.888h-77.824v-44.544h210.432v44.544z"
                        fill="#FF5959"
                        p-id="4116"
                      ></path>
                      <path
                        d="M1222.144 352.768h-1.536c-41.984 0-69.632 34.304-69.632 89.088s27.136 89.088 69.632 89.088 70.656-35.328 70.656-89.6c0.512-54.272-26.624-88.576-69.12-88.576z"
                        fill="#FF5959"
                        p-id="4117"
                      ></path>
                    </svg>
                  </div>
                </li>
                <li
                  @mouseenter="navMark = 2"
                  @mouseout="navMark = navActive"
                  :class="{ on: navMark == 2 }"
                >
                  <a @click="goPage(2)">课程理念</a>
                </li>
                <li
                  @mouseenter="navMark = 3"
                  @mouseout="navMark = navActive"
                  :class="{ on: navMark == 3 }"
                >
                  <a @click="goPage(3)">课程体系</a>
                </li>
                <li
                  @mouseenter="navMark = 4"
                  @mouseout="navMark = navActive"
                  :class="{ on: navMark == 4 }"
                >
                  <a @click="goPage(4)">关于我们</a>
                  <dl>
                    <dd><a @click="goPage(4, 0)">品牌简介</a></dd>
                    <dd><a @click="goPage(4, 1)">业务板块</a></dd>
                    <dd><a @click="goPage(4, 2)">创始人</a></dd>
                    <dd><a @click="goPage(4, 3)">招聘职位</a></dd>
                    <dd><a @click="goPage(4, 4)">联系我们</a></dd>
                  </dl>
                </li>
              </ul>
            </nav>
          </div>
          <div class="free">
            <a class="btn" @click="getFree()">
              <img
                src="../assets/picture/header_icon.png"
                alt=""
                class="img01"
              />
              <img
                src="../assets/picture/header_icon_on.png"
                alt=""
                class="img02"
              />
              <span>免费领取专属启蒙课</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup name="header">
import { onMounted, ref ,watch} from "vue";
import { useRouter,useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const navMark = ref(0);
const navActive = ref(0);
let nameList = ["Home", "Mother", "Course", "System", "About"];


watch(
  () => route.name,
  (newPath, oldPath) => {
    setTimeout(() => {
      let index = nameList.indexOf(route.name);
      navMark.value = index != -1 ? index : 0;
      navActive.value = navMark.value;
    }, 20);
  },
  { immediate: true }
);
const emit = defineEmits(["showfree"]);
const goPage = (index, id = null) => {
  if (navActive.value == index && index == 4) {
    router.replace({ query: { id: id } });
    return;
  }
  navActive.value = index;

  if (id) {
    router.push({
      name: nameList[index],
      query: {
        id: id,
      },
    });
  } else {
    router.push({
      name: nameList[index],
    });
  }
};
const getFree = () => {
  emit("showfree");
};
</script>
  
  
<style scoped lang="scss">
.header-container {
  height: 120px;
  position: relative;
}

.top-h {
  height: 120px;
  position: fixed;
  box-sizing: border-box;
  padding: 40px 0 0;
  z-index: 10000;
  width: 100%;
  background: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  top: 0;

  .top-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .logo-left {
      img {
        position: relative;
        top: -10px;
      }
      a {
        cursor: pointer;
      }
    }

    .nav-rigth {
      height: 80px;
      display: flex;

      .nav-list {
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 30px;

        nav {
          ul {
            li {
              padding: 0 0;
              font-size: 16px;
              color: #764497;
              display: inline-block;
              line-height: 74px;
              position: relative;
              height: 75px;

              .hot svg {
                position: absolute;
                top: -10px;
                right: 19px;
                width: 44px;
                height: 30px;
                animation: topyp 1.2s ease-in-out infinite;
              }

              a {
                font-size: 20px;
                color: #764497;
                position: relative;
                display: inline-block;
                padding: 0 20px;
                cursor: pointer;
              }

              dl {
                background: #5ebfb0;
                text-align: center;
                box-sizing: border-box;
                height: 0;
                overflow: hidden;
                transition: height 1s;
                dd {
                  a {
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    color: #fff;
                    display: block;
                    background: #68cabb;
                    transition: 0.6s;
                  }

                  a:hover {
                    background: #eeb033;
                  }
                }
              }
            }

            li:before {
              content: "";
              position: absolute;
              right: 0;
              width: 2px;
              height: 16px;
              background: #eeb033;
              top: 50%;
              margin-top: -9px;
            }

            li:last-child:before {
              display: none;
            }

            li:after {
              content: "";
              border-left: transparent 18px solid;
              border-right: transparent 18px solid;
              border-bottom: #5ebfb0 24px solid;
              position: absolute;
              left: 50%;
              margin-left: -18px;
              bottom: -8px;
              opacity: 0;
              transition: 0.6s;
            }

            li.on:after {
              opacity: 1;
            }

            li.on > a,
            li:hover > a {
              font-weight: bold;
            }
            li:hover dl {
              height: 210px;
              padding: 5px 0;
            }
          }
        }
      }

      .free {
        display: inline-block;
        height: 74px;
        line-height: 74px;
        border-radius: 40px;
        overflow: hidden;

        a {
          display: inline-block;
          background: #f8b41c;
          font-size: 18px;
          color: #764497;
          border-radius: 40px;
          padding: 0 25px;
          overflow: hidden;
          cursor: pointer;
          transform: rotate(0deg);
          position: relative;
          z-index: 1;
          animation: scaleDraw 1.2s ease-in-out infinite;

          img {
            margin: -3px 2px 0 0;
            position: relative;
          }

          img.img01 {
            display: none;
          }

          span {
            position: relative;
          }
        }

        a:before {
          content: "";
          position: absolute;
          width: 360px;
          height: 360px;
          top: 50%;
          margin-top: -180px;
          left: 50%;
          margin-left: -180px;
          background: #764497;
          transform: scale(0);
          transition: 1s ease;
          border-radius: 50%;
          opacity: 0;
        }

        a:hover img.img01 {
          display: inline-block;
        }

        a:hover img.img02 {
          display: none;
        }

        a:hover span {
          color: #f8b41c;
        }

        a:hover:before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

@keyframes scaleDraw {
  0% {
    transform: scale(0.97);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.97);
  }
}

@keyframes topyp {
  0% {
    top: -10px;
  }

  50% {
    top: -7px;
  }

  100% {
    top: -10px;
  }
}
</style>
  