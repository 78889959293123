<template>
  <div class="home-container">
    <div class="index-banner">
      <div class="item">
        <a>
          <div class="items"></div>
        </a>
      </div>
      <div class="item" v-if="false">
        <a>
          <div class="items first"></div>
        </a>
      </div>
    </div>
    <!-- 数字 -->
    <div class="number-wrap">
      <div class="w1200">
        <ul>
          <li>
            <a href="" data-aos="flip-left" data-aos-duration="1500">
              <div class="box">
                <div class="icon">
                  <div class="table">
                    <div class="table_cell">
                      <img
                        src="../assets/picture/container08_icon01.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h3>
                  <span class="counter">
                    <count-to
                      :starVal="0"
                      :endVal="234"
                      :duration="2000"
                    ></count-to>
                  </span>
                  <font>w</font>
                </h3>
                <h4>家庭选择</h4>
              </div>
            </a>
          </li>
          <li>
            <a href="" data-aos="flip-left" data-aos-duration="1500">
              <div class="box">
                <div class="icon">
                  <div class="table">
                    <div class="table_cell">
                      <img
                        src="../assets/picture/container08_icon02.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h3>
                  <span class="counter">
                    <count-to
                      :starVal="0"
                      :endVal="240"
                      :duration="2000"
                    ></count-to
                  ></span>
                  <font>+</font>
                </h3>
                <h4>原创课程</h4>
              </div>
            </a>
          </li>
          <li>
            <a href="" data-aos="flip-left" data-aos-duration="1500">
              <div class="box">
                <div class="icon">
                  <div class="table">
                    <div class="table_cell">
                      <img
                        src="../assets/picture/container08_icon03.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h3>
                  <span class="counter">
                    <count-to
                      :starVal="0"
                      :endVal="82"
                      :duration="2000"
                    ></count-to
                  ></span>
                  <font>+</font>
                </h3>
                <h4>城市覆盖</h4>
              </div>
            </a>
          </li>
          <li>
            <a href="" data-aos="flip-left" data-aos-duration="1500">
              <div class="box">
                <div class="icon">
                  <div class="table">
                    <div class="table_cell">
                      <img
                        src="../assets/picture/container08_icon04.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h3>
                  <span class="counter">
                    <count-to
                      :starVal="0"
                      :endVal="48"
                      :duration="2000"
                    ></count-to>
                  </span>
                  <font>+</font>
                </h3>
                <h4>精选主题</h4>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tutu-wrap">
      <img src="../assets/picture/container11_tit.jpg" alt="" />
    </div>

    <!-- 轮播图 -->
    <div class="swipers-wrap">
      <div class="w94">
        <div class="swiper-container" id="swiper-container1">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="img slow">
                <video
                  src="https://website-1322289346.cos.ap-beijing.myqcloud.com/website/video/%E5%B0%8F%E8%8A%8B%E5%9C%86%E5%A6%88%E5%A6%88.mp4"
                  autoplay
                  controls
                ></video>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="img slow">
                <video
                  src="https://website-1322289346.cos.ap-beijing.myqcloud.com/website/video/%E6%99%B4%E6%99%B4%E5%A6%88.mp4"
                  autoplay
                  controls
                 
                ></video>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="img slow">
                <video
                  src="https://website-1322289346.cos.ap-beijing.myqcloud.com/website/video/%E9%A3%8E%E7%AD%9D%E5%A6%88.mp4"
                  autoplay
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-container" id="swiper-container2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="bb">
                <img
                  src="../assets/picture/swiper-container2_bg03.png"
                  alt=""
                />
                <div class="top">
                  <div class="table">
                    <div class="table_cell">小睛晴妈妈</div>
                  </div>
                </div>
              </div>
              <p>
                全职妈妈<br />
                <font>2018年4月</font>加入启智茗星
              </p>
            </div>
            <div class="swiper-slide">
              <div class="bb">
                <img
                  src="../assets/picture/swiper-container2_bg01.png"
                  alt=""
                />
                <div class="top">
                  <div class="table">
                    <div class="table_cell">小芋圆妈妈</div>
                  </div>
                </div>
              </div>
              <p>
                培训机构负责人<br />
                <font>2018年5月</font>加入启智茗星
              </p>
            </div>
            <div class="swiper-slide">
              <div class="bb">
                <img
                  src="../assets/picture/swiper-container2_bg02.png"
                  alt=""
                />
                <div class="top">
                  <div class="table">
                    <div class="table_cell">风筝妈妈</div>
                  </div>
                </div>
              </div>
              <p>
                哑巴英语只会读写<br />
                <font>2019年7月</font>加入启智茗星
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tutu-wrap">
      <img src="../assets/picture/container12_tit.jpg" alt="" />
    </div>

    <div class="bg-wrap01">
      <div class="bg" data-aos="fade-left" data-aos-duration="1500">
        <div
          class="top"
          data-aos="fade-right"
          data-aos-duration="1500"
          aos-delay="500"
        >
          <img src="../assets/picture/container03_img02.png" alt="" />
        </div>
      </div>
    </div>

    <div class="bg-wrap02">
      <div class="bg" data-aos="fade-right" data-aos-duration="1500">
        <div
          class="top"
          data-aos="fade-left"
          data-aos-duration="1500"
          aos-delay="500"
        >
          <img src="../assets/picture/container04_img02.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bg-wrap03">
      <div class="bg" data-aos="fade-right" data-aos-duration="1500">
        <div
          class="top"
          data-aos="fade-right"
          data-aos-duration="1500"
          aos-delay="500"
        >
          <img src="../assets/picture/container05_img02.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bg-wrap04">
      <div class="w1200">
        <div class="title">
          <img src="../assets/picture/container06_tit.png" alt="" />
        </div>

        <div class="center" data-aos="zoom-in" data-aos-duration="1500">
          <img src="../assets/picture/container06_center.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bg-wrap05">
      <div class="w1200">
        <div class="title">
          <img src="../assets/picture/container07_tit.png" alt="" />
        </div>

        <div class="center" data-aos="zoom-in" data-aos-duration="1500">
          <img src="../assets/picture/container07_center.png" alt="" />
        </div>
      </div>
    </div>

    <div class="bg-wrap06">
      <div class="w1200">
        <div class="title">
          <h3><font>TEACHING IDEA</font><span>教学理念</span></h3>
        </div>
        <ul>
          <li>
            <a data-aos="fade-up" data-aos-duration="1500">
              <div class="box">
                <h3>
                  <img src="../assets/picture/container09_icon01.png" alt="" />
                </h3>
                <div class="con">
                  <div class="table">
                    <div class="table_cell">
                      <h5>TPR全身互动法<br />Total Physical Response</h5>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a data-aos="fade-up" data-aos-duration="1500">
              <div class="box">
                <h3>
                  <img src="../assets/picture/container09_icon02.png" alt="" />
                </h3>
                <div class="con">
                  <div class="table">
                    <div class="table_cell">
                      <h5>SLA二语习得理论<br />Second Language Acquisition</h5>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a data-aos="fade-up" data-aos-duration="1500">
              <div class="box">
                <h3>
                  <img src="../assets/picture/container09_icon03.png" alt="" />
                </h3>
                <div class="con">
                  <div class="table">
                    <div class="table_cell">
                      <h5>STEAM综合教育法</h5>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a data-aos="fade-up" data-aos-duration="1500">
              <div class="box">
                <h3>
                  <img src="../assets/picture/container09_icon04.png" alt="" />
                </h3>
                <div class="con">
                  <div class="table">
                    <div class="table_cell">
                      <h5>家庭蒙氏教育法<br />Montessori at home</h5>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup name="home">
import { ref, onMounted } from "vue";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";

import { CountTo } from "vue3-count-to";

onMounted(() => {
  const Swiper2 = new Swiper("#swiper-container2", {
    autoplay: {
      delay: 4000, //1秒切换一次
    },
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    centeredSlides: !0,
    watchSlidesProgress: !0,
    pagination: ".swiper-pagination",
    paginationClickable: !0,
  });
  const Swiper1 = new Swiper("#swiper-container1", {
    //自动播放(单位为毫秒)

    autoplay: {
      // 自动滑动
      delay: 4000, //1秒切换一次
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: "auto",
    spaceBetween: -50,
    centeredSlides: !0,
    watchSlidesProgress: !0,
    pagination: ".swiper-pagination",
    paginationClickable: !0,
    controller: {
      control: Swiper2, //控制Swiper1
    },
  });
});
</script>
<style lang="scss" scoped>
.index-banner {
  height: 576px;
  background: #682a8d;

  .item {
    height: 576px;
    position: relative;

    .items {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      background: url(../assets/images/b5dadb475062e17.jpg) center no-repeat;
    }

    .first {
      background: url(../assets/images/69132c3672060dd.jpg) center no-repeat;
    }
  }
}

.number-wrap {
  background: #ffffff;
  position: relative;

  .piao {
    position: absolute;
    top: -112px;
    right: 10%;
    width: 134px;
    height: 134px;
    border-radius: 50%;
    background: #5fc9b9;
  }

  ul {
    font-size: 0;

    li {
      display: inline-block;
      width: 25%;
      text-align: center;
      overflow: hidden;

      a {
        display: block;
        padding: 44px 0 0;
      }

      a:hover .icon:before {
        transform: scale(1.2);
        background: rgba(117, 66, 150, 0.15);
      }

      a:hover .icon .table {
        transform: scale(0.98);
      }

      a:hover .icon img {
        -webkit-animation: jello 1s;
        -moz-animation: jello 1s;
        animation: jello 1s;
      }

      .icon {
        display: inline-block;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        position: relative;

        img {
          margin-top: -3px;
          max-height: 55px;
        }

        .table {
          background: #754296;
          border-radius: 50%;
          position: relative;
          transform: scale(1);
          transition: 0.6s;
        }
      }

      .icon:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(117, 66, 150, 0.05);
        border-radius: 50%;
        transform: scale(1.11);
        transition: 0.6s;
      }

      h3 {
        font-family: BarlowCondensed-Regular;
        font-size: 44px;
        color: #754296;
        margin: 12px 0 4px;

        font {
          font-family: BarlowCondensed-Regular;
          font-size: 44px;
        }
      }

      h4 {
        font-size: 25px;
        color: #754296;
      }
    }

    li:last-child a {
      display: block;
      padding: 44px 0 0;
    }
  }
}

.tutu-wrap {
  margin-top: 80px;
  text-align: center;
}

.swipers-wrap {
  .w94 {
    width: 768px;
    margin: 0 auto;

    #swiper-container1 {
      padding: 0 170px;

      .swiper-slide {
        width: 75%;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        transform-style: preserve-3d;
        opacity: 1 !important;
      }
    }

    .swiper-slide-active .img:after {
      border: #7e47a5 5px solid;
    }

    .swiper-slide .img {
      transform: scale(0.8);
    }

    .swiper-slide-active .img {
      transform: scale(1);
    }

    .swiper-slide .main-img {
      width: 80%;
      margin: 0 auto;
      display: block;
    }

    .img {
      position: relative;
      overflow: hidden;
      border-radius: 32px;
      margin: 0 1.5rem;
      transition: 0.6s;

      video {
        width: 100%;
        height: 440px;
        object-fit: cover;
      }
    }

    .img:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: #eeaf33 5px solid;
      border-radius: 32px;
      pointer-events: none;
    }

    .top {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }

    #swiper-container2 {
      text-align: center;
      margin-top: 40px;

      .bb {
        width: 200px;
        display: inline-block;
        position: relative;
        margin-bottom: 5px;

        .top {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          font-size: 20px;
          font-family: zzh;
          color: #fff;
          font-weight: bold;
        }

        img {
          width: 100%;
        }
      }

      p {
        font-size: 16px;
        line-height: 30px;
        color: #7e46a5;

        font {
          color: #eba92d;
        }
      }
    }
  }
}

.bg-wrap01 {
  height: 1032px;
  position: relative;
  margin-bottom: 122px;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1032px;
    transition: 1.2s;

    background: url(../assets/images/container03_img01.jpg) center no-repeat;

    .top {
      position: absolute;
      left: 50%;
      margin-left: -414px;
      top: 618px;
    }
  }
}

.bg-wrap02 {
  height: 652px;
  position: relative;
  margin-bottom: 50px;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 652px;
    transition: 1.2s;
    background: url(../assets/images/container04_img01.jpg) center no-repeat;

    .top {
      position: absolute;
      left: 50%;
      margin-left: 15px;
      top: 492px;
    }
  }
}

.bg-wrap03 {
  height: 918px;
  position: relative;
  margin-bottom: 122px;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1041px;
    transition: 1.2s;
    background: url(../assets/images/container05_img01.jpg) center no-repeat;

    .top {
      position: absolute;
      left: 50%;
      margin-left: -414px;
      top: 722px;
    }
  }
}

.bg-wrap04 {
  background: url(../assets/images/container06_bg.jpg) center no-repeat;
  height: 1025px;
  padding: 158px 0 0;

  .title {
    margin-bottom: 75px;
    text-align: center;
  }

  .center {
    text-align: center;
  }
}

.bg-wrap05 {
  background: url(../assets/images/container07_bg.jpg) center no-repeat;
  height: 865px;

  .title {
    margin-bottom: 70px;
    text-align: center;
  }

  .center {
    text-align: center;
  }
}

.bg-wrap06 {
  padding: 92px 0 92px;
  background: #fbf3ff;

  .title {
    margin-bottom: 50px;

    h3 {
      font-size: 44px;
      color: #682a8d;
      text-align: center;
      line-height: 36px;

      font {
        text-transform: uppercase;
        vertical-align: top;
        display: inline-block;
        margin-right: 10px;
        transform: scaleY(1.15);
        font-weight: bold;
        margin-top: 2px;
      }
    }
  }

  ul {
    font-size: 0;

    li {
      display: inline-block;
      width: 25%;
      text-align: center;

      a {
        display: block;

        h3 {
          line-height: 128px;
          margin-bottom: 20px;

          img {
            -webkit-animation: jello 1s;
            -moz-animation: jello 1s;
            animation: jello 1s;
          }
        }

        h5 {
          font-size: 16px;
          color: #682a8d;
          line-height: 22px;
        }
      }
    }
  }
}

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
</style>